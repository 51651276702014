export default [
  {
    path: "/",
    redirect: "/home",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/views/layout/index.vue"),
    children: [
      {
        name: "Home",
        path: "/home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
        meta: {
          keepAlive: false,
          title: "首页总览",
        },
      },
      {
        name: "Consulting",
        path: "/consulting",
        component: () =>
          import(
            /* webpackChunkName: "consulting" */ "@/views/consulting-detail/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "合作咨询",
        },
      },
      {
        name: "CompanyProfile",
        path: "/companyProfile",
        component: () =>
          import(
            /* webpackChunkName: "CompanyProfile" */ "@/views/companyProfile/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "合作咨询",
        },
      },
      {
        name: "Recruitment",
        path: "/recruitment",
        component: () =>
          import(
            /* webpackChunkName: "Recruitment" */ "@/views/recruitment/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "人才招聘",
        },
      },
      {
        name: "news",
        path: "/news",
        component: () =>
          import(/* webpackChunkName: "news" */ "@/views/news/index.vue"),
        meta: {
          keepAlive: false,
          title: "新闻中心",
        },
      },
      {
        name: "newsDetail",
        path: "/newsDetail",
        component: () =>
          import(
            /* webpackChunkName: "newsDetail" */ "@/views/newsDtail/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "新闻详情",
        },
      },
      {
        name: "plan",
        path: "/plan",
        component: () =>
          import(/* webpackChunkName: "plan" */ "@/views/plan/index.vue"),
        meta: {
          keepAlive: false,
          title: "行业方案",
        },
      },
      {
        name: "planDetail",
        path: "/planDetail",
        component: () =>
          import(
            /* webpackChunkName: "planDetail" */ "@/views/planDetail/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "方案详情",
        },
      },
      {
        name: "projectList",
        path: "/projectList",
        component: () =>
          import(
            /* webpackChunkName: "projectList" */ "@/views/projectList/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "项目案例",
        },
      },
      {
        name: "projectDetail",
        path: "/projectDetail",
        component: () =>
          import(
            /* webpackChunkName: "projectDetail" */ "@/views/projectDetail/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "项目详情",
        },
      },
      {
        name: "goodsList",
        path: "/goodsList",
        component: () =>
          import(
            /* webpackChunkName: "goodsList" */ "@/views/goodsList/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "产品列表",
        },
      },
      {
        name: "algorithm",
        path: "/algorithm",
        component: () =>
          import(
            /* webpackChunkName: "algorithm" */ "@/views/algorithm/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "核心算法技术",
        },
      },
      {
        name: "parsing",
        path: "/parsing",
        component: () =>
          import(/* webpackChunkName: "parsing" */ "@/views/parsing/index.vue"),
        meta: {
          keepAlive: false,
          title: "智能解析产品",
        },
      },
      {
        name: "engine",
        path: "/engine",
        component: () =>
          import(/* webpackChunkName: "engine" */ "@/views/engine/index.vue"),
        meta: {
          keepAlive: false,
          title: "智能解析产品",
        },
      },
      {
        name: "eyes",
        path: "/eyes",
        component: () =>
          import(/* webpackChunkName: "eyes" */ "@/views/eyes/index.vue"),
        meta: {
          keepAlive: false,
          title: "慧眼系列",
        },
      },
      {
        name: "night",
        path: "/night",
        component: () =>
          import(/* webpackChunkName: "night" */ "@/views/night/index.vue"),
        meta: {
          keepAlive: false,
          title: "慧眼系列",
        },
      },
      {
        name: "image",
        path: "/image",
        component: () =>
          import(
            /* webpackChunkName: "xiaofang" */ "@/views/xiaofang/index.vue"
          ),
        meta: {
          keepAlive: false,
          title: "消防融合管理平台",
        },
      },
    ],
  },
];
